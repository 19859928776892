<template src="@/../../../templates/brand/components/templates/user/PaymentProfiles.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import CreditProfiles from '@/components/CreditProfiles.vue'

export default {
  name: 'PaymentProfiles',
  components: { CreditProfiles },
  mounted () {
    if (!this.locations || !this.locations.length)
      this.getLocations(this.user._id)
  },
  data () {
    return {
      locales: _.keys(this.$store.state.lang),
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user,
      locations: ({ Location }) => Location.locations,
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    billingAddress () {
      if (!this.user || !this.user.profile) {
        console.error(' :::: no user profile found in payment profiles...')
        return {address: {}}
      }
      return this.locations.find(l => l._id === this.user.profile.billing)
    },
    moneris_hpp_url () {
      return this.appconfig && this.appconfig.siteConfig.checkout ? this.appconfig.siteConfig.checkout.gatewayHost : ''
    },
    moneris_tokenization_id () {
      return this.appconfig && this.appconfig.siteConfig.checkout ? this.appconfig.siteConfig.checkout.profileId : ''
    },
  },
  methods: {
    ...mapActions(['getUser', 'getLocations', 'profileUpdate']),
    creditProfileCallback (type, data) {
      let _profile = _.assign({}, this.user.profile)
      switch (type) {
        case 'start':
          break
        case 'new':
          return this.getUser(this.user._id)
          // break
        case 'select':
          _profile.extradata.mtidDefault = data.DataKey // this.ccprofiles.find(p => p.DataKey === id);
          this.profileUpdate({ user: this.user, profile: _profile })
          break
        case 'update':
          // ?
          break
        case 'delete':
          if (data.msg && ~data.msg.indexOf('active orders')) {
            let _orders = data.data.orders.map(
              o => `<a href="/myaccount/orders/${o._id}">${o.referenceNum}</a>`
            )
            this.$vex.dialog.alert({
              unsafeMessage: `This card is currently used on active orders:<br> ${_orders.join(
                ', '
              )}.<br> You must assign a different card to ${
                _orders.length === 1 ? 'the order' : 'these orders'
              } before you can remove this card.`
            })
          }
      }
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/user/PaymentProfiles.css"></style>
